import React, { useState } from 'react';
import { Logo } from '../Images';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styling/Navbar.css';


const Navbar = () => {

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const about = () => {
        navigate('/about');
    };

    const home = () => {
        navigate('/');
    };

    const contact = () => {
        navigate('/contact');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            role="banner"
            className={`uui-navbar02_component w-nav ${isOpen ? 'menu-open' : ''}`}
        >
            <div className="uui-navbar02_container">
                <a href="#" className="uui-navbar02_logo-link w-nav-brand">
                    <div className="uui-logo_component-2">
                        <img
                            src={Logo}
                            alt="UI logo"
                            className="uui-logo_logotype-2"
                        />
                    </div>
                </a>
                <nav role="navigation" className={`uui-navbar02_menu w-nav-menu ${isOpen ? 'menu-open' : ''}`}>
                    <div className="uui-navbar02_menu-left">
                        <button
                            onClick={home}
                            className="uui-navbar02_link w-nav-link w--current"
                        >
                            {t('Navbar.navlink1')}
                        </button>
                        <button onClick={about} className="uui-navbar02_link w-nav-link">
                            {t('Navbar.navlink2')}

                        </button>
                        <button onClick={contact} className="uui-navbar02_link w-nav-link">
                            {t('Navbar.navlink3')}

                        </button>
                    </div>
                    <div className="uui-navbar02_menu-right">
                        <div className="uui-navbar02_button-wrapper">
                            <Link to='/packages' className="uui-button-2 w-inline-block">
                                <div>{t('Home.button1')}</div>
                            </Link>
                            <div
                                className="uui-button-2 w-inline-block"
                                style={{ backgroundColor: 'white', border: '1px solid black' }}
                            >
                                <div className='uui-button-wrapper-5 max-width-full-mobile-landscape'>
                                    <Link to="/enquire" style={{ textDecoration: 'none', color: 'black' }}>
                                        <div>{t('Home.button2')}</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="uui-navbar02_menu-button w-nav-button" onClick={toggleMenu}>
                    <div className={`menu-icon_component-2 ${isOpen ? 'open' : ''}`}>
                        <div className="menu-icon_line" />
                        <div className="menu-icon_line" />
                        <div className="menu-icon_line" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
