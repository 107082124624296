import React, { useRef, useEffect } from 'react';
import '../styling/App.css';
import '../styling/Index.css';
import '../styling/Style.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { motion, useInView } from 'framer-motion';
import { Rajasthan, Kerala, Assam, Himachal, Uttarakhand, Customers } from '../Images';
import Video from '../assets/videos/hero_video.mp4';
import Homepage from '../assets/videos/hero_video.mp4';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import useDocumentTitle from './UseDocumentTitle';
import { useTranslation } from 'react-i18next';


function Home() {
    const { t } = useTranslation();

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useDocumentTitle('Soul Trails - Your Expert Travel Guide ');
    const navigate = useNavigate();

    const handleStateSelect = (state) => {
        navigate(`/state/${state}`);
        window.scrollTo(0, 0);
    };


    const ref = useRef(null);
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const isInView = useInView(ref, { once: true, margin: '-10%' });
    const isInView1 = useInView(ref1, { once: true, margin: '-10%' });
    const isInView2 = useInView(ref2, { once: true, margin: '-15%' });

    const slideUp = {
        hidden: {
            y: '10vh',
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 0.9,
                ease: [0.42, 0, 0.58, 1],
            },
        },
    };

    const staggerContainer = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };
    return (
        <>
            <Navbar />
            <div className="modal-wrapper">
                <div className="modal-form w-form">
                    <form
                        id="wf-form-Testimonial-Form"
                        name="wf-form-Testimonial-Form"
                        data-name="Testimonial Form"
                        method="get"
                        className="modal-form-elements-wrap"
                        data-wf-flow="27fd5a2379f3039044cbd1c5"
                        data-wf-page-id="6653509d3a6b64efd39ad67c"
                        data-wf-element-id="fdb32211-aca9-5489-fb56-18fd9709764b"
                    >
                        <div
                            id="w-node-fdb32211-aca9-5489-fb56-18fd9709764c-97097649"
                            className="uui-max-width-xsmall"
                            style={{ color: "#000" }}
                        >
                            <div
                                id=" w-node-fdb32211-aca9-5489-fb56-18fd9709764d-97097649"
                                className="uui-text-align-center"
                            >
                                <div
                                    id="w-node-fdb32211-aca9-5489-fb56-18fd9709764e-97097649"
                                    className="web-heading-h4 web-heading-paragraph"
                                    style={{ color: "#000" }}
                                >
                                    Share your experience.
                                </div>
                                <div className="web-heading-paragraph web-body-small web-body-paragraph">
                                    Write us your experience with Soul Trail and feature it on our
                                    website. Let's people encourage explore!
                                </div>
                            </div>
                        </div>
                        <div
                            id="w-node-fdb32211-aca9-5489-fb56-18fd97097652-97097649"
                            className="modal-form-elements-row"
                        >
                            <div
                                id="w-node-fdb32211-aca9-5489-fb56-18fd97097653-97097649"
                                className="uui-form-field-wrapper"
                            >
                                <label htmlFor="Customer-Name-2" className="uui-field-label">
                                    Name
                                </label>
                                <input
                                    className="uui-form_input w-input"
                                    maxLength={256}
                                    name="Customer-Name-2"
                                    data-name="Customer Name 2"
                                    placeholder="Your full name"
                                    type="text"
                                    id="Customer-Name-2"
                                    required=""
                                />
                            </div>
                            <div
                                id="w-node-fdb32211-aca9-5489-fb56-18fd97097657-97097649"
                                className="uui-form-field-wrapper"
                            >
                                <label htmlFor="Email-Address-2" className="uui-field-label">
                                    Email
                                </label>
                                <input
                                    className="uui-form_input w-input"
                                    maxLength={256}
                                    name="Email-Address-2"
                                    data-name="Email Address 2"
                                    placeholder="example@email.com"
                                    type="email"
                                    id="Email-Address-2"
                                    required=""
                                />
                            </div>
                        </div>
                        <div className="uui-form-field-wrapper">
                            <label htmlFor="Review-2" className="uui-field-label">
                                Review
                            </label>
                            <textarea
                                className="uui-form_input text-area w-input"
                                maxLength={400}
                                name="Review-2"
                                data-name="Review 2"
                                placeholder="Type your review..."
                                minLength={60}
                                id="Review-2"
                                required=""
                                defaultValue={""}
                            />
                            <div className="web-body-small text-style-muted">
                                Review size between 100 to 400 characters
                            </div>
                        </div>
                        <div
                            id="w-node-fdb32211-aca9-5489-fb56-18fd97097661-97097649"
                            className="uui-form-button-wrapper"
                        >
                            <a
                                id="w-node-fdb32211-aca9-5489-fb56-18fd97097662-97097649"
                                data-w-id="fdb32211-aca9-5489-fb56-18fd97097662"
                                href="#"
                                className="uui-button-secondary-gray w-button"
                            >
                                Close
                            </a>
                            <input
                                type="submit"
                                data-wait="Please wait..."
                                id="w-node-fdb32211-aca9-5489-fb56-18fd97097664-97097649"
                                className="uui-button w-button"
                                defaultValue="Submit"
                            />
                        </div>
                    </form>
                    <div className="success-message w-form-done">
                        <div className="success-text">
                            Thank you! Your submission has been received!
                        </div>
                    </div>
                    <div className="error-message w-form-fail">
                        <div className="error-text">
                            Oops! Something went wrong while submitting the form.
                        </div>
                    </div>
                </div>
                <div
                    data-w-id="fdb32211-aca9-5489-fb56-18fd9709766b"
                    className="modal-overlay"
                />
            </div>
            <div className="page-wrapper">
                <header className="uui-section_heroheader02">
                    <div className="position-relative z-index-2">
                        <div className="uui-page-padding">
                            <div className="uui-container-large">
                                <div className="uui-padding-vertical-xhuge">
                                    <motion.div className="ani-slide-in-parent" variants={staggerContainer} animate="visible" initial="hidden">
                                        <div className="w-layout-grid uui-heroheader02_component">
                                            <motion.div className="uui-heroheader02_content" variants={slideUp}>
                                                <div className="ani-slide-in-on-parent is-child-01">
                                                    <h1 className="web-heading-h1 web-heading-home web-heading-paragraph">
                                                        {t('Home.header')}
                                                    </h1>
                                                </div>
                                                <div className="ani-slide-in-on-parent is-child-02">
                                                    <div className="web-body-medium text-style-muted web-body-paragraph">
                                                        {t('Home.header-section')}
                                                    </div>
                                                </div>
                                                <div className="ani-slide-in-on-parent is-child-03">
                                                    <div className="herosection-btn-wrapper">
                                                        <div className="uui-button-row">
                                                            <div className="uui-button-wrapper-5 max-width-full-mobile-landscape">
                                                                <Link
                                                                    to="/packages"
                                                                    className="uui-button is-button-large w-inline-block"
                                                                >
                                                                    <div className="web-body-regular">
                                                                        {t('Home.button1')}
                                                                    </div>
                                                                    <div className="icon-embed-xsmall w-embed">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="100%"
                                                                            height="100%"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            preserveAspectRatio="xMidYMid meet"
                                                                            aria-hidden="true"
                                                                            role="img"
                                                                        >
                                                                            <path
                                                                                d="M7 17L17 7M17 7H7M17 7V17"
                                                                                stroke="currentColor"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                            <div className="uui-button-wrapper-5 max-width-full-mobile-landscape">
                                                                <Link
                                                                    to="/enquire"
                                                                    className="uui-button-secondary-gray-6 is-button-large w-inline-block"
                                                                >
                                                                    <div className="web-body-regular">
                                                                        {t('Home.button2')}
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        data-poster-url="videos/homepage_video-poster-00001.jpg"
                        data-video-urls="videos/homepage_video-transcode.mp4,videos/homepage_video-transcode.webm"
                        data-autoplay="true"
                        data-loop="true"
                        data-wf-ignore="true"
                        className="heo-bg-video w-background-video w-background-video-atom"
                    >
                        <video
                            id="026a71f4-a72b-8562-721a-77e5ef780a37-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            data-wf-ignore="true"
                            data-object-fit="cover"
                        >
                            <source src={Video} data-wf-ignore="true" />
                            <source
                                src={Homepage}
                                data-wf-ignore="true"
                            />
                        </video>
                    </div>
                    <div className="hero-bg-overlay" />
                </header>
                <header className="uui-section_header05">
                    <div className="uui-page-padding">
                        <div className="uui-container-large">
                            <div className="uui-padding-vertical-xhuge">
                                <motion.div className="ani-slide-in-parent" ref={ref}
                                    initial="hidden"
                                    animate={isInView ? 'visible' : 'hidden'}
                                    variants={slideUp}>
                                    <div className="w-layout-grid uui-header05_component" >
                                        <div className="ani-slide-in-on-parent is-child-01">
                                            <div className="uui-header05_content-left">
                                                <div className="uui-heading-subheading">
                                                    <div className="web-body-regular"> {t('Home.featured')}</div>
                                                </div>
                                                <div className="uui-heading-large">
                                                    <h1 className="web-heading-h2">
                                                        {t('Home.events')}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ani-slide-in-on-parent is-child-04 w-dyn-list">
                                        <div
                                            role="list"
                                            className="hero_featured-location-wrap w-dyn-items"

                                        >
                                            <div role="listitem" className="w-dyn-item">
                                                <div
                                                    onClick={() => handleStateSelect('rajasthan')}
                                                    className="hero_featured-location-card w-inline-block"
                                                >
                                                    <div className="w-layout-hflex hero_featured-location-card-wrap">
                                                        <div className="w-layout-vflex hero_featured-location-card-top">
                                                            <div className="icon-embed-xsmall w-embed">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="100%"
                                                                    height="100%"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    aria-hidden="true"
                                                                    role="img"
                                                                >
                                                                    <path
                                                                        d="M15.0002 15.0001V9.00005M15.0002 9.00005H9.00019M15.0002 9.00005L9.00019 14.9999M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div id="city-name" className="web-body-medium">
                                                                Rajasthan
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={Rajasthan}
                                                        loading="lazy"
                                                        alt=""
                                                        className="hero_featured-location-card-img"
                                                    />
                                                    <div className="hero_featured-location-card-filter" />
                                                </div>
                                            </div>
                                            <div role="listitem" className="w-dyn-item">
                                                <div
                                                    onClick={() => handleStateSelect('assam')}
                                                    className="hero_featured-location-card w-inline-block"
                                                >
                                                    <div className="w-layout-hflex hero_featured-location-card-wrap">
                                                        <div className="w-layout-vflex hero_featured-location-card-top">
                                                            <div className="icon-embed-xsmall w-embed">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="100%"
                                                                    height="100%"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    aria-hidden="true"
                                                                    role="img"
                                                                >
                                                                    <path
                                                                        d="M15.0002 15.0001V9.00005M15.0002 9.00005H9.00019M15.0002 9.00005L9.00019 14.9999M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div id="city-name" className="web-body-medium">
                                                                Assam
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={Assam}
                                                        loading="lazy"
                                                        alt=""
                                                        className="hero_featured-location-card-img"
                                                    />
                                                    <div className="hero_featured-location-card-filter" />
                                                </div>
                                            </div>
                                            <div role="listitem" className="w-dyn-item">
                                                <div
                                                    onClick={() => handleStateSelect('kerala')}
                                                    className="hero_featured-location-card w-inline-block"
                                                >
                                                    <div className="w-layout-hflex hero_featured-location-card-wrap">
                                                        <div className="w-layout-vflex hero_featured-location-card-top">
                                                            <div className="icon-embed-xsmall w-embed">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="100%"
                                                                    height="100%"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    aria-hidden="true"
                                                                    role="img"
                                                                >
                                                                    <path
                                                                        d="M15.0002 15.0001V9.00005M15.0002 9.00005H9.00019M15.0002 9.00005L9.00019 14.9999M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div id="city-name" className="web-body-medium">
                                                                Kerala
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={Kerala}
                                                        loading="lazy"
                                                        alt=""
                                                        className="hero_featured-location-card-img"
                                                    />
                                                    <div className="hero_featured-location-card-filter" />
                                                </div>
                                            </div>
                                            <div role="listitem" className="w-dyn-item">
                                                <div
                                                    onClick={() => handleStateSelect('himachal_pradesh')}
                                                    className="hero_featured-location-card w-inline-block"
                                                >
                                                    <div className="w-layout-hflex hero_featured-location-card-wrap">
                                                        <div className="w-layout-vflex hero_featured-location-card-top">
                                                            <div className="icon-embed-xsmall w-embed">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="100%"
                                                                    height="100%"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    aria-hidden="true"
                                                                    role="img"
                                                                >
                                                                    <path
                                                                        d="M15.0002 15.0001V9.00005M15.0002 9.00005H9.00019M15.0002 9.00005L9.00019 14.9999M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div id="city-name" className="web-body-medium">
                                                                Himachal Pradesh
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={Himachal}
                                                        loading="lazy"
                                                        alt=""
                                                        className="hero_featured-location-card-img"
                                                    />
                                                    <div className="hero_featured-location-card-filter" />
                                                </div>
                                            </div>
                                            <div role="listitem" className="w-dyn-item">
                                                <div
                                                    onClick={() => handleStateSelect('uttarakhand')}
                                                    className="hero_featured-location-card w-inline-block"
                                                >
                                                    <div className="w-layout-hflex hero_featured-location-card-wrap">
                                                        <div className="w-layout-vflex hero_featured-location-card-top">
                                                            <div className="icon-embed-xsmall w-embed">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="100%"
                                                                    height="100%"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    aria-hidden="true"
                                                                    role="img"
                                                                >
                                                                    <path
                                                                        d="M15.0002 15.0001V9.00005M15.0002 9.00005H9.00019M15.0002 9.00005L9.00019 14.9999M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div id="city-name" className="web-body-medium">
                                                                Uttarakhand
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={Uttarakhand}
                                                        loading="lazy"
                                                        alt=""
                                                        className="hero_featured-location-card-img"
                                                    />
                                                    <div className="hero_featured-location-card-filter" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.div className="ani-slide-in-parent" ref={ref1}
                                    initial="hidden"
                                    animate={isInView1 ? 'visible' : 'hidden'}
                                    variants={slideUp}>
                                    <div className="ani-slide-in-on-parent is-child-01">
                                        <div className="w-layout-grid uui-header05_component">
                                            <div className="uui-header05_content-left">
                                                <div className="uui-heading-subheading">
                                                    <div className="web-body-regular"> {t('Home.story')}</div>
                                                </div>
                                                <div className="uui-heading-large">
                                                    <h1 className="web-heading-h2">
                                                        {t('Home.story-text')}
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="uui-max-width-xsmall">
                                                <div className="web-body-medium">
                                                    {t('Home.story-text2')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uui-button-row">
                                            <div className="uui-button-wrapper">
                                                <Link
                                                    to="/about"
                                                    className="uui-button-secondary-gray w-inline-block"
                                                >
                                                    <div className="web-body-regular"> {t('Home.button3')}</div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </header>
                <header className="uui-section_heroheader23">
                    <motion.div className="uui-page-padding-4" ref={ref2}
                        initial="hidden"
                        animate={isInView2 ? 'visible' : 'hidden'}
                        variants={slideUp}>
                        <div className="uui-container-large-4">
                            <div className="ani-slide-in-parent">
                                <div className="uui-padding-vertical-xlarge">
                                    <div className="uui-heroheader23_component">
                                        <div className="uui-heroheader23_content">
                                            <div>
                                                <div
                                                    id="w-node-_8bb7f6da-b690-a83a-b6d0-465a76eb2ef2-d39ad67c"
                                                    className="ani-slide-in-on-parent is-child-01"
                                                >
                                                    <div
                                                        id="w-node-_0b4a3462-80e4-033c-0d10-8eae98e8ede1-d39ad67c"
                                                        className="uui-heroheader23_content-left"
                                                    >
                                                        <h1 className="web-heading-h2 web-heading-paragraph">
                                                            {t('Home.packages')}
                                                        </h1>
                                                        <div className="uui-max-width-large-11">
                                                            <div className="web-body-regular">
                                                                {t('Home.packages-text')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ani-slide-in-on-parent is-child-03">
                                                    <div className="uui-button-row">
                                                        <div className="uui-button-wrapper">
                                                            <a
                                                                href="#"
                                                                className="uui-button-secondary-gray w-inline-block"
                                                            >
                                                                <div className="web-body-regular">
                                                                    {t('Home.button4')}
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                id="w-node-_46e1a4bd-fa97-8c13-8f5c-3a4fcdd59fd6-d39ad67c"
                                                className="ani-slide-in-on-parent is-child-03"
                                            >
                                                <div
                                                    id="w-node-_0b4a3462-80e4-033c-0d10-8eae98e8edf8-d39ad67c"
                                                    className="uui-heroheader23_content-right"
                                                >
                                                    <div className="uui-heroheader23_item-list">
                                                        <div
                                                            id="w-node-_0b4a3462-80e4-033c-0d10-8eae98e8edfa-d39ad67c"
                                                            className="uui-heroheader23_item"
                                                        >
                                                            <div className="uui-heroheader23_item-icon-wrapper">
                                                                <div className="uui-icon-1x1-small w-embed">
                                                                    <svg
                                                                        viewBox="0 0 32 32"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                                                                            fill="#D1FADF"
                                                                        />
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M22.7952 9.85322L13.2485 19.0666L10.7152 16.3599C10.2485 15.9199 9.51522 15.8932 8.98188 16.2666C8.46188 16.6532 8.31522 17.3332 8.63522 17.8799L11.6352 22.7599C11.9285 23.2132 12.4352 23.4932 13.0085 23.4932C13.5552 23.4932 14.0752 23.2132 14.3685 22.7599C14.8485 22.1332 24.0085 11.2132 24.0085 11.2132C25.2085 9.98655 23.7552 8.90655 22.7952 9.83989V9.85322Z"
                                                                            fill="#12B76A"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className="uui-heroheader23_item-text-wrapper">
                                                                <div className="web-body-medium"> {t('Home.services')}</div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id="w-node-_0b4a3462-80e4-033c-0d10-8eae98e8ee00-d39ad67c"
                                                            className="uui-heroheader23_item"
                                                        >
                                                            <div className="uui-heroheader23_item-icon-wrapper">
                                                                <div className="uui-icon-1x1-small w-embed">
                                                                    <svg
                                                                        viewBox="0 0 32 32"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                                                                            fill="#D1FADF"
                                                                        />
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M22.7952 9.85322L13.2485 19.0666L10.7152 16.3599C10.2485 15.9199 9.51522 15.8932 8.98188 16.2666C8.46188 16.6532 8.31522 17.3332 8.63522 17.8799L11.6352 22.7599C11.9285 23.2132 12.4352 23.4932 13.0085 23.4932C13.5552 23.4932 14.0752 23.2132 14.3685 22.7599C14.8485 22.1332 24.0085 11.2132 24.0085 11.2132C25.2085 9.98655 23.7552 8.90655 22.7952 9.83989V9.85322Z"
                                                                            fill="#12B76A"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className="uui-heroheader23_item-text-wrapper">
                                                                <div className="web-body-medium"> {t('Home.services1')}</div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id="w-node-_0b4a3462-80e4-033c-0d10-8eae98e8ee06-d39ad67c"
                                                            className="uui-heroheader23_item"
                                                        >
                                                            <div className="uui-heroheader23_item-icon-wrapper">
                                                                <div className="uui-icon-1x1-small w-embed">
                                                                    <svg
                                                                        viewBox="0 0 32 32"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                                                                            fill="#D1FADF"
                                                                        />
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M22.7952 9.85322L13.2485 19.0666L10.7152 16.3599C10.2485 15.9199 9.51522 15.8932 8.98188 16.2666C8.46188 16.6532 8.31522 17.3332 8.63522 17.8799L11.6352 22.7599C11.9285 23.2132 12.4352 23.4932 13.0085 23.4932C13.5552 23.4932 14.0752 23.2132 14.3685 22.7599C14.8485 22.1332 24.0085 11.2132 24.0085 11.2132C25.2085 9.98655 23.7552 8.90655 22.7952 9.83989V9.85322Z"
                                                                            fill="#12B76A"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className="uui-heroheader23_item-text-wrapper">
                                                                <div className="web-body-medium">
                                                                    {t('Home.services2')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </header>
                <section className="uui-section_layout17">
                    <motion.div className="uui-page-padding-3" ref={ref2}
                        initial="hidden"
                        animate={isInView2 ? 'visible' : 'hidden'}
                        variants={slideUp}>
                        <div className="uui-container-large-3" variants={slideUp}>
                            <div className="ani-slide-in-parent">
                                <div className="uui-padding-vertical-xlarge">
                                    <div className="w-layout-grid uui-layout17_component">
                                        <div
                                            id="w-node-_5cedba4b-c469-fe3a-e427-64a982bb60c8-d39ad67c"
                                            className="uui-layout17_content"
                                        >
                                            <div className="w-layout-grid uui-layout17_item-list">
                                                <div
                                                    id="w-node-a4787a00-ded4-5dcf-3ba3-b09cbfa9ec64-d39ad67c"
                                                    className="ani-slide-in-on-parent is-child-01"
                                                >
                                                    <div
                                                        id="w-node-_5cedba4b-c469-fe3a-e427-64a982bb60ca-d39ad67c"
                                                        className="uui-layout17_item"
                                                    >
                                                        <div className="layout17_item-icon-wrapper">
                                                            <div className="uui-icon-featured-outline-large">
                                                                <div className="uui-icon-1x1-xsmall-3 w-embed">
                                                                    <svg
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M6.09436 11.2288C6.03221 10.8282 5.99996 10.4179 5.99996 10C5.99996 5.58172 9.60525 2 14.0526 2C18.4999 2 22.1052 5.58172 22.1052 10C22.1052 10.9981 21.9213 11.9535 21.5852 12.8345C21.5154 13.0175 21.4804 13.109 21.4646 13.1804C21.4489 13.2512 21.4428 13.301 21.4411 13.3735C21.4394 13.4466 21.4493 13.5272 21.4692 13.6883L21.8717 16.9585C21.9153 17.3125 21.9371 17.4895 21.8782 17.6182C21.8266 17.731 21.735 17.8205 21.6211 17.8695C21.4911 17.9254 21.3146 17.8995 20.9617 17.8478L17.7765 17.3809C17.6101 17.3565 17.527 17.3443 17.4512 17.3448C17.3763 17.3452 17.3245 17.3507 17.2511 17.3661C17.177 17.3817 17.0823 17.4172 16.893 17.4881C16.0097 17.819 15.0524 18 14.0526 18C13.6344 18 13.2237 17.9683 12.8227 17.9073M7.63158 22C10.5965 22 13 19.5376 13 16.5C13 13.4624 10.5965 11 7.63158 11C4.66668 11 2.26316 13.4624 2.26316 16.5C2.26316 17.1106 2.36028 17.6979 2.53955 18.2467C2.61533 18.4787 2.65322 18.5947 2.66566 18.6739C2.67864 18.7567 2.68091 18.8031 2.67608 18.8867C2.67145 18.9668 2.65141 19.0573 2.61134 19.2383L2 22L4.9948 21.591C5.15827 21.5687 5.24 21.5575 5.31137 21.558C5.38652 21.5585 5.42641 21.5626 5.50011 21.5773C5.5701 21.5912 5.67416 21.6279 5.88227 21.7014C6.43059 21.8949 7.01911 22 7.63158 22Z"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="uui-layout17_item-text-wrapper">
                                                            <h3 className="web-heading-h5">
                                                                {t('Home.services3')}
                                                            </h3>
                                                            <div className="uui-space-xxsmall" />
                                                            <div className="web-body-regular">
                                                                {t('Home.services3-text')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    id="w-node-b02a37c0-15bc-963c-2795-7be1a135fc85-d39ad67c"
                                                    className="ani-slide-in-on-parent is-child-02"
                                                >
                                                    <div
                                                        id="w-node-_5cedba4b-c469-fe3a-e427-64a982bb60d4-d39ad67c"
                                                        className="uui-layout17_item"
                                                    >
                                                        <div className="layout17_item-icon-wrapper">
                                                            <div className="uui-icon-featured-outline-large">
                                                                <div className="uui-icon-1x1-xsmall-3 w-embed">
                                                                    <svg
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M9 17.5H3.5M6.5 12H2M9 6.5H4M17 3L10.4036 12.235C10.1116 12.6438 9.96562 12.8481 9.97194 13.0185C9.97744 13.1669 10.0486 13.3051 10.1661 13.3958C10.3011 13.5 10.5522 13.5 11.0546 13.5H16L15 21L21.5964 11.765C21.8884 11.3562 22.0344 11.1519 22.0281 10.9815C22.0226 10.8331 21.9514 10.6949 21.8339 10.6042C21.6989 10.5 21.4478 10.5 20.9454 10.5H16L17 3Z"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="uui-layout17_item-text-wrapper">
                                                            <h3 className="web-heading-h5">
                                                                {t('Home.services4')}
                                                            </h3>
                                                            <div className="uui-space-xxsmall" />
                                                            <div className="web-body-regular">
                                                                {t('Home.services4-text')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    id="w-node-_55343b88-d849-78d2-6916-3d98c3b0e9b6-d39ad67c"
                                                    className="ani-slide-in-on-parent is-child-03"
                                                >
                                                    <div
                                                        id="w-node-_5cedba4b-c469-fe3a-e427-64a982bb60de-d39ad67c"
                                                        className="uui-layout17_item"
                                                    >
                                                        <div className="layout17_item-icon-wrapper">
                                                            <div className="uui-icon-featured-outline-large">
                                                                <div className="uui-icon-1x1-xsmall-3 w-embed">
                                                                    <svg
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M11 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V13M12 8H16V12M15.5 3.5V2M19.4393 4.56066L20.5 3.5M20.5103 8.5H22.0103M3 13.3471C3.65194 13.4478 4.31987 13.5 5 13.5C9.38636 13.5 13.2653 11.3276 15.6197 8"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="uui-layout17_item-text-wrapper">
                                                            <h3 className="web-heading-h5"> {t('Home.services5')}</h3>
                                                            <div className="uui-space-xxsmall" />
                                                            <div className="web-body-regular">
                                                                {t('Home.services5-text')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    id="w-node-_9941cc7f-1422-58ac-0cc4-9effeeef2cfb-d39ad67c"
                                                    className="ani-slide-in-on-parent is-child-04"
                                                >
                                                    <div
                                                        id="w-node-fb50d0a7-ec3e-1526-5548-6ac08c9e7843-d39ad67c"
                                                        className="uui-layout17_item"
                                                    >
                                                        <div className="layout17_item-icon-wrapper">
                                                            <div className="uui-icon-featured-outline-large">
                                                                <div className="uui-icon-1x1-xsmall-3 w-embed">
                                                                    <svg
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M11 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V13M12 8H16V12M15.5 3.5V2M19.4393 4.56066L20.5 3.5M20.5103 8.5H22.0103M3 13.3471C3.65194 13.4478 4.31987 13.5 5 13.5C9.38636 13.5 13.2653 11.3276 15.6197 8"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="uui-layout17_item-text-wrapper">
                                                            <h3 className="web-heading-h5">
                                                                {t('Home.services6')}
                                                            </h3>
                                                            <div className="uui-space-xxsmall" />
                                                            <div className="web-body-regular">
                                                                {t('Home.services6-text')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="w-node-_9be5a936-8ec8-bcfc-452b-1093d391a43d-d39ad67c"
                                            className="ani-slide-in-on-parent is-child-02"
                                        >
                                            <div className="uui-layout17_image-wrapper">
                                                <img
                                                    src={Customers}
                                                    loading="lazy"
                                                    alt="Credit card mockups"
                                                    className="uui-layout17_image"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </section>
                <section className="uui-section_cta08">
                    <div className="uui-page-padding-3">
                        <div className="uui-container-small ani-slide-in-from-bottom">
                            <div className="uui-padding-vertical-xhuge-12">
                                <div className="uui-text-align-center-7">
                                    <h2 className="web-heading-h2"> {t('Home.outro')}</h2>
                                    <div className="uui-space-xsmall-2" />
                                    <div className="web-body-regular">
                                        {t('Home.outro-text')}
                                    </div>
                                    <div className="uui-space-medium" />
                                    <div className="uui-button-row-2 button-row-center is-reverse-mobile-landscape">
                                        <div className="uui-button-wrapper-8 max-width-full-mobile-landscape">
                                            <Link
                                                to="/contact"
                                                className="uui-button-secondary-gray-11 w-inline-block"
                                            >
                                                <div> {t('Home.button5')}</div>
                                            </Link>
                                        </div>
                                        <div className="uui-button-wrapper-8 max-width-full-mobile-landscape">
                                            <a href="#" className="uui-button-13 w-inline-block">
                                                <div> {t('Home.button6')}</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </>

    )
}

export default Home