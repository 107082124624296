import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Packages from './components/Packages';
import StateDetails from './components/StateDetails'; 
import Enquire from './components/Enquire';
import TermsCondition from './components/TermsCondition';
import PrivacyPolicy from './components/PrivacyPolicy';
import ReturnRefund from './components/ReturnRefund';
import './translate';

const App = () => {
  return (
    <Suspense fallback="Loading...">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/enquire" element={<Enquire />} />
          <Route path="/state/:stateName" element={<StateDetails />} />
          <Route path="/termscondition" element={<TermsCondition />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/returnrefund" element={<ReturnRefund />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
