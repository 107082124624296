import React, { useEffect, useRef, useState } from "react";
import Navbar from './Navbar';
import Footer from './Footer';
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import { Link } from 'react-router-dom';

const Enquire = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    packages: "",
    travelType: "",
    peopleCount: "",
    travelDate: "",
    additionalRequirements: "",
  });

  const dateInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const phoneInputInstance = useRef(null);

  useEffect(() => {
    const fp = flatpickr(dateInputRef.current, {
      enableTime: false,
      dateFormat: "Y-m-d",
      minDate: "today", // Disallow past dates
      onChange: (selectedDates, dateStr) => {
        setFormData((prev) => ({ ...prev, travelDate: dateStr }));
      },
    });

    phoneInputInstance.current = intlTelInput(phoneInputRef.current, {
      initialCountry: "in",
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });

    return () => {
      fp.destroy();
      phoneInputInstance.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.value = formData.phone;
    }
  }, [formData.phone]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = () => {
    const phoneValue = phoneInputInstance.current.getNumber();
    setFormData((prev) => ({ ...prev, phone: phoneValue }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, phone, city, packages, travelType, peopleCount, travelDate, additionalRequirements } = formData;

    const message = `Name: ${encodeURIComponent(name)}
Email: ${encodeURIComponent(email)}
Phone: ${encodeURIComponent(phone)}
City: ${encodeURIComponent(city)}
Package: ${encodeURIComponent(packages || 'Not selected')}
Travel Type: ${encodeURIComponent(travelType || 'Not selected')}
People Count: ${encodeURIComponent(peopleCount || 'Not selected')}
Travel Date: ${encodeURIComponent(travelDate)}
Additional Requirements: ${encodeURIComponent(additionalRequirements)}`;

    const phoneNumber = '919151515841';
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const url = isMobile
      ? `whatsapp://send?phone=${phoneNumber}&text=${message}`
      : `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

    window.open(url, "_blank");
  };

  return (
    <>
      <Navbar />
      <section className="page-wrapper">
        <section className="uui-section_contact02">
          <div className="uui-page-padding-2">
            <div className="uui-container-xsmall">
              <div className="uui-padding-vertical-xhuge">
                <div className="uui-text-align-center">
                  <div className="uui-heading-subheading">Fill Out Your Info</div>
                  <h2 className="web-heading-h4 web-heading-paragraph">
                    Let's learn about you for the best recommendation
                  </h2>
                  <div className="web-body-regular text-style-muted">
                    We have some questions to ask to provide you the personalized
                    experience. Please take 2 mins and fill up this form. Thank You!
                  </div>
                  <div className="uui-space-medium" />
                </div>
                <div className="uui-contact02_component w-form">
                  <form
                    id="wf-form-Survey-Form"
                    name="wf-form-Survey-Form"
                    data-name="Survey Form"
                    method="get"
                    className="uui-contact02_form"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-field-2col-inputs">
                      <div className="uui-form-field-wrapper-inputs">
                        <label htmlFor="Customer-Name" className="uui-field-label-4">Full Name</label>
                        <input
                          className="uui-form_input-5 w-input"
                          maxLength={256}
                          name="name"
                          placeholder="e.g., Emily Smith"
                          type="text"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="uui-form-field-wrapper-inputs">
                        <label htmlFor="Customer-Email" className="uui-field-label-4">Email</label>
                        <input
                          className="uui-form_input-5 w-input"
                          maxLength={256}
                          name="email"
                          placeholder="e.g., name@email.com"
                          type="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="uui-form-field-wrapper-inputs">
                        <label htmlFor="Customer-Phone" className="uui-field-label-4">Phone Number</label>
                        <input
                          className="uui-form_input-5 w-input"
                          maxLength={256}
                          name="phone"
                          placeholder="XXX-XXX-XXXX"
                          type="tel"
                          ref={phoneInputRef}
                          onChange={handlePhoneChange}
                          required
                        />
                      </div>
                      <div className="uui-form-field-wrapper-inputs">
                        <label htmlFor="Customer-City" className="uui-field-label-4">City</label>
                        <input
                          className="uui-form_input-5 w-input"
                          maxLength={256}
                          name="city"
                          placeholder="Enter Your City"
                          type="text"
                          value={formData.city}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="divider-bottom" />

                    <div className="form-field-2col is-questions">
                      <div className="uui-form-field-wrapper">
                        <div className="web-heading-h4 web-heading-paragraph">Select your package:</div>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="packages"
                            value="3nights/4days"
                            checked={formData.packages === "3nights/4days"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">
                            3 nights / 4 days
                          </span>
                        </label>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="packages"
                            value="5nights/6days"
                            checked={formData.packages === "5nights/6days"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">
                            5 nights / 6 days
                          </span>
                        </label>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="packages"
                            value="7nights+"
                            checked={formData.packages === "7nights+"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">
                            7 nights+
                          </span>
                        </label>
                      </div>

                      {/* Travel Type */}
                      <div className="uui-form-field-wrapper">
                        <div className="web-heading-h4 web-heading-paragraph">How are you travelling?</div>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="travelType"
                            value="Family"
                            checked={formData.travelType === "Family"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">Family</span>
                        </label>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="travelType"
                            value="Couple"
                            checked={formData.travelType === "Couple"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">Couple</span>
                        </label>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="travelType"
                            value="Friends"
                            checked={formData.travelType === "Friends"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">Friends</span>
                        </label>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="travelType"
                            value="Solo"
                            checked={formData.travelType === "Solo"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">Solo</span>
                        </label>
                      </div>

                      <div className="uui-form-field-wrapper">
                        <div className="web-heading-h4 web-heading-paragraph">How many people are travelling?</div>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="peopleCount"
                            value="1-2"
                            checked={formData.peopleCount === "1-2"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">1-2</span>
                        </label>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="peopleCount"
                            value="3-5"
                            checked={formData.peopleCount === "3-5"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">3-5</span>
                        </label>
                        <label className="form_radio-wrapper w-radio">
                          <input
                            type="radio"
                            name="peopleCount"
                            value="5+"
                            checked={formData.peopleCount === "5+"}
                            onChange={handleChange}
                          />
                          <span className="form_radio-button web-body-medium w-form-label">5+</span>
                        </label>
                      </div>
                    </div>

                    <div className="divider-bottom" />

                    <div className="uui-form-field-wrapper">
                      <div className="web-heading-h4 web-heading-paragraph">When are you travelling?</div>
                      <input
                        type="text"
                        ref={dateInputRef}
                        className="uui-form_input-5 w-input"
                        name="travelDate"
                        placeholder="Select Date"
                        value={formData.travelDate}
                        required
                      />
                    </div>

                    {/* Additional Requirements */}
                    <div className="uui-form-field-wrapper">
                      <label htmlFor="Additional-Requirements" className="uui-field-label-4">Additional Requirements</label>
                      <textarea
                        className="uui-form_input-5 w-input"
                        maxLength={5000}
                        name="additionalRequirements"
                        placeholder="Let us know any additional requirements"
                        value={formData.additionalRequirements}
                        onChange={handleChange}
                      />
                    </div>

                    <label id="Contact-2-Checkbox" className="w-checkbox uui-form-checkbox-4">
                      <input type="checkbox" className="w-checkbox-input w-checkbox-input--inputType-custom uui-form-checkbox-icon-4"
                        data-name="Contact 02 checkbox" required />
                      <span htmlFor="Contact-02-checkbox" className="uui-form-checkbox-label-4 w-form-label">You agree to our
                        friendly <Link to="/privacypolicy" className="uui-text-style-link-5">privacy policy</Link>.</span>
                    </label>

                    <div id="w-node-f9a15c77-0f23-0be2-0b3a-d184e23982e1-10ea9512" className="uui-form-button-wrapper-4">
                      <input type="submit" data-wait="Please wait..." id="submit-button" className="uui-button-10 w-button"
                        value="Submit" />
                    </div>
                  </form>
                  <div className="uui-form-success w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="uui-form-error w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default Enquire;