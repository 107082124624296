import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './translation/en.json';
import esTranslations from './translation/es.json';
import frTranslations from './translation/fr.json';
import geTranslations from './translation/ge.json';
import itTranslations from './translation/it.json';
import jpTranslations from './translation/jp.json';
import mdTranslations from './translation/md.json';

i18n
  .use(LanguageDetector) 
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      es: { translation: esTranslations },
      fr: { translation: frTranslations },
      ge: { translation: geTranslations },
      it: { translation: itTranslations },
      jp: { translation: jpTranslations },
      md: { translation: mdTranslations },
    },
    fallbackLng: 'ge',
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
